import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";

import { Grid, ButtonBase, Typography, Button, Tabs, Tab, Box, Paper, Avatar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CalculateIcon from "@mui/icons-material/Calculate";
import {
  Refresh,
  Edit as EditIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Password as PasswordIcon,
  WifiPassword as WifiPasswordIcon,
  Call as CallIcon,
  MobileFriendly as MobileFriendlyIcon,
  Person as PersonIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  PendingActions as PendingActionsIcon,
  Payments as PaymentsIcon,
  CreditScore as CreditScoreIcon,
} from "@mui/icons-material";

import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import useSetting from "../../../hooks/useSetting";

import ProfileImg from "../../icons/profile_img.png";

import ChangeAppPassword from "../../users/userlist/components/ChangeAppPass";
import SettingsIcon from "@mui/icons-material/Settings";
import MailIcon from "@mui/icons-material/Mail";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmsIcon from "@mui/icons-material/Sms";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Settings from "../tenant/general_settings/index";
import useTenant from "../../../hooks/useTenant";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Address from "./components/AddressPage";
import Contact from "./components/ContactPage";
import { a11yProps } from "../../../utils/common";
import TabPanel from "../../../components/TabPanel";
import UploadInLog from "./components/UploadInvoLog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowPassword from "./components/ShowPass";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [
    {
      field: "createdAt",
      sort: "DESC",
    },
  ],
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function TenantDetailPage(props) {
  const classes = useTabStyles();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const { getTenant, tenant } = useTenant();
  const { getTenantSetting, tenantSetting, updateTenantSetting, isSubmitting } = useSetting();

  const [payload, setPayload] = useState(currentPayload);

  console.log(searchParams.get("id"), "kdkkkkk");

  const fetchTenantData = async () => {
    await getTenant(searchParams.get("id"));
  };

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      const currentPayload = {
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: searchParams.get("id"),
            op: "eq",
            dataType: "string",
            condition: "and",
            isJoin: false,
          },
        ],
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
        sortFields: [],
      };
      fetchTenantData();
    }
  }, [searchParams]);

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const settingClick = (id) => {
    navigate("/cpanel/tenant/general-setting", { state: { id: searchParams.get("id") } });
  };

  const mailClick = () => {
    navigate("/cpanel/tenant/email-setting", { state: { id: searchParams.get("id") } });
  };

  const smsClick = () => {
    navigate("/cpanel/tenant/sms-setting", { state: { id: searchParams.get("id") } });
  };

  const invoiceClick = (id) => {
    return openDialog(
      "upload_invoice_logo",
      "Upload Invoice Logo",
      <UploadInLog key={getTimeStamp()} />,
    );
  };

  const showPass = (id) => {
    return openDialog(
      "show_password",
      "Show Password",
      <ShowPassword key={getTimeStamp()} radUserId={searchParams.get("id")} />,
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={ProfileImg} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={2}>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <PersonIcon fontSize="large" />
                  </Grid>
                  <Grid item>Tenant Name</Grid>
                  <Grid item>{tenant.tenantName}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <MobileFriendlyIcon fontSize="large" />
                  </Grid>
                  <Grid item>Mobile</Grid>
                  <Grid item>{tenant.mobileNo}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <MailIcon fontSize="large" />
                  </Grid>
                  <Grid item>Email</Grid>
                  <Grid item>
                    {" "}
                    <a href={"mailto: " + tenant.emailId}>{tenant.emailId}</a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <AccountBalanceWalletIcon fontSize="large" />
                  </Grid>
                  <Grid item>Gst No</Grid>
                  <Grid item>{tenant.gstNo}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <BusinessCenterIcon fontSize="large" />
                  </Grid>
                  <Grid item>Business Type</Grid>
                  <Grid item>{tenant.businessType}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<SettingsIcon />}
                  onClick={() => settingClick()}
                >
                  General Settings
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<MailOutlineIcon />}
                  onClick={() => mailClick()}
                >
                  Email Setting
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" startIcon={<SmsIcon />} onClick={() => smsClick()}>
                  Sms Setting
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<ReceiptIcon />}
                  onClick={() => invoiceClick()}
                >
                  Upload Invoice Logo
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  onClick={() => showPass()}
                >
                  Show Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          border: 1,
          borderColor: "divider",
          flexGrow: 1,

          marginTop: 4,
          width: "100%",
        }}
      >
        <Tabs
          classes={{ scroller: classes.scroller }}
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          aria-label="Company"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            justifyContent: "center",
            width: "1000px",
          }}
        >
          <Tab label="Address Details" {...a11yProps(0)} />
          <Tab label="Contact Details" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Address />
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl>
            <Contact />
          </Grid>
        </TabPanel>
      </Box>
    </React.Fragment>
  );
}

export default TenantDetailPage;
