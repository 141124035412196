import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { searchPayload } from "../../../utils/common";
import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useUser from "../../../hooks/useUser";
import useComplaintType from "../../../hooks/useComplaintType";
import useEmployee from "../../../hooks/useEmployee";
import useTenant from "../../../hooks/useTenant";
import useAuth from "../../../hooks/useAuth";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function ComplaintFields(props) {
  const { fields, hideAssignee, userNameDisabled } = props;
  const {
    autoSearchUser,
    userAutoSearchResults,
    getUser,
    autoSearchLoading,
    user: radUser,
  } = useUser();
  const searchPayloadValue = searchPayload();

  const { autoSearchComplaintType, complaintTypeAutoSearchResults } = useComplaintType();
  const { autoSearchEmployee, employeeAutoSearchResults } = useEmployee();
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();
  const { user } = useAuth();

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const tenant = watch("tenant");

  console.log(tenant, "tteeennna");

  const userName = watch("userName");

  const [userOptions, setUserOptions] = useState([]);
  const [complaintTypeOptions, setComplaintTypeOptions] = useState([]);
  const [complaintTypePayload, setComplaintTypePayload] = useState(currentPayload);
  const [userPayload, setUserPayload] = useState(searchPayloadValue);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [employeePayload, setEmployeePayload] = useState(currentPayload);

  const [tenantPayload, setTenantPayload] = useState(currentPayload);
  const [tenantOptions, setTenantOptions] = useState([]);

  useEffect(() => {
    if (userName != undefined && Object.keys(userName).length != 0) {
      getUser(userName.value);
    }
    console.log(userName, "userNameuserName");
  }, [userName]);

  useEffect(() => {
    if (Object.keys(radUser).length !== 0) {
      setValue("customerName", radUser.firstName + " " + radUser.lastName);
      setValue("mobileNo", radUser.mobileNo);
    }
    console.log(radUser, "user details");
  }, [radUser]);

  useEffect(() => {
    console.log(userAutoSearchResults, "userAutoSearchResults");
    if (userAutoSearchResults && userAutoSearchResults.data.length !== 0) {
      const results = [...userOptions, ...userAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setUserOptions(filteredResults);
    }
  }, [userAutoSearchResults]);

  // complaintType

  const fetchComplaintType = async () => {
    await autoSearchComplaintType(complaintTypePayload);
  };
  useEffect(() => {
    fetchComplaintType();
  }, [complaintTypePayload]);

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  useEffect(() => {
    console.log(user, "user.businessType");
    if (
      user &&
      (user.businessType == "SUPER_ADMIN" ||
        user.businessType == "DISTRIBUTOR" ||
        user.businessType == "LOCAL_CABLE_OPERATOR" ||
        user.businessType == "MULTI_SERVICE_OPERATOR")
    ) {
      fetchTenants();
    }
  }, [user, tenantPayload]);

  useEffect(() => {
    console.log(tenant, "tenanttenanttenant");
    if (tenant != null && Object.keys(tenant).length != 0) {
      const payload = {
        ...userPayload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      setUserOptions([]);
      setValue("userName", null);
      fetchUsers(payload);
    }
  }, [tenant]);

  const fetchUsers = async (payload) => {
    await autoSearchUser(payload);
  };

  useEffect(() => {
    console.log(complaintTypeAutoSearchResults, "complaintTypeAutoSearchResults");
    if (complaintTypeAutoSearchResults && complaintTypeAutoSearchResults.data.length !== 0) {
      const results = [...complaintTypeOptions, ...complaintTypeAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setComplaintTypeOptions(filteredResults);
    }
  }, [complaintTypeAutoSearchResults]);

  // employee

  const fetchEmployee = async () => {
    await autoSearchEmployee(employeePayload);
  };
  useEffect(() => {
    fetchEmployee();
  }, [employeePayload]);

  useEffect(() => {
    console.log(employeeAutoSearchResults, "employeeAutoSearchResults");
    if (employeeAutoSearchResults && employeeAutoSearchResults.data.length !== 0) {
      const results = [...employeeOptions, ...employeeAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(filteredResults);
    }
  }, [employeeAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      {user &&
        (user.businessType === "SUPER_ADMIN" ||
          user.businessType == "DISTRIBUTOR" ||
          user.businessType == "LOCAL_CABLE_OPERATOR" ||
          user.businessType == "MULTI_SERVICE_OPERATOR") && (
          <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
            <SearchableAutoComplete
              control={control}
              data={tenantOptions}
              label="Select Tenant"
              payload={tenantPayload}
              setPayload={setTenantPayload}
              loading={false}
              name="tenant"
              multiple={false}
              defaultValue={""}
              hasNextPage={false}
              fields={fields}
            />
          </Grid>
        )}
      <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          control={control}
          data={userOptions}
          label={t("customer_user_name")}
          payload={userPayload}
          setPayload={setUserPayload}
          loading={autoSearchLoading}
          name="userName"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          disabled={userNameDisabled}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("customer_name")}
          name="customerName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          disabled={true}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("mobile_number")}
          name="mobileNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
          disabled={userNameDisabled}
        />
      </Grid>
      {!hideAssignee && (
        <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
          <SearchableAutoComplete
            label={t("assign_to_employee")}
            name="employee"
            data={employeeOptions}
            control={control}
            payload={employeePayload}
            setPayload={setEmployeePayload}
            loading={false}
            fullWidth
            variant="outlined"
            errors={errors}
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={fields}
          />
        </Grid>
      )}

      <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          control={control}
          data={complaintTypeOptions}
          label={t("complaint_type")}
          name="complaintType"
          // control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          payload={complaintTypePayload}
          setPayload={setComplaintTypePayload}
          loading={false}
          // name="userName"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
        />
      </Grid>

      <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="priority"
          label={t("priority")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {/* <MenuItem value={"Medium"}>ACTIVE</MenuItem> */}
          <MenuItem value={"IMMEDIATE"}>IMMEDIATE</MenuItem>
          <MenuItem value={"HIGH"}>HIGH</MenuItem>
          <MenuItem value={"MEDIUM"}>MEDIUM</MenuItem>
          <MenuItem value={"LOW"}>LOW</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("subject")}
          name="subject"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>

      <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
        <CustomTextField
          multiline
          rows={1}
          maxRows={4}
          label={t("comments")}
          name="comments"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
    </Grid>
  );
}

export default ComplaintFields;
