import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import SendIcon from "@mui/icons-material/Send";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { useDialog } from "../../../../contexts/DialogContext";
import CustomTextField from "../../../../components/CustomTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import SaveIcon from "@mui/icons-material/Save";
import useUser from "../../../../hooks/useUser";

import {
  Grid,
  Divider as MuiDivider,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import TextField from "@mui/material/TextField";
import { useForm, FormProvider } from "react-hook-form";
import useInventoryManagement from "../../../../hooks/useInventoryManagement";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const validationSchema = Yup.object().shape({
  material: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  uom: Yup.string().required("Required"),
  qty: Yup.string().required("Required"),
});

function AddMaterial(props) {
  const { addInventoryManagement, getInventoryManagement, inventoryManagement, isRenewSubmitting } =
    useInventoryManagement();

  const { addMaterial } = useUser();

  const { userId, refreshPage } = props;

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const methods = useForm({
    defaultValues: {
      material: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const dispatch = useDispatch();
  const { unSetDialog, openDialog } = useDialog();
  const { t } = useTranslation();

  const selectedMaterial = watch("material");

  const { autoSearchInventoryManagement, inventoryManagementAutoSearchResults } =
    useInventoryManagement();
  const [inventoryManagementOptions, setinventoryManagementOptions] = useState([]);
  const [inventoryManagementPayload, setinventoryManagementPayload] = useState(currentPayload);

  React.useEffect(() => {
    if (Object.keys(inventoryManagement).length !== 0) {
      setValue("uom", inventoryManagement.uom);
    }
  }, [inventoryManagement]);

  const fetchInventoryManagement = async () => {
    await autoSearchInventoryManagement(inventoryManagementPayload);
  };
  useEffect(() => {
    fetchInventoryManagement();
  }, [inventoryManagementPayload]);

  const fetchData = async () => {
    await getInventoryManagement(selectedMaterial.value);
  };

  useEffect(() => {
    if (selectedMaterial != undefined && Object.keys(selectedMaterial).length !== 0) {
      console.log(selectedMaterial, "qwertyuqwert");
      fetchData();
    }
  }, [selectedMaterial]);

  useEffect(() => {
    console.log(inventoryManagementAutoSearchResults, "inventoryManagementAutoSearchResults");

    if (
      inventoryManagementAutoSearchResults &&
      inventoryManagementAutoSearchResults.data.length !== 0
    ) {
      const results = [...inventoryManagementOptions, ...inventoryManagementAutoSearchResults.data];

      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value && t.label),
      );

      filteredResults.sort((a, b) => (a.label || "").localeCompare(b.label || ""));

      setinventoryManagementOptions(filteredResults);
    }
  }, [inventoryManagementAutoSearchResults]);

  const onSubmit = async (data) => {
    try {
      console.log(data, "==========================0000");
      console.log(userId, "dfghjmk");
      let payload = {
        radUserId: userId,
        materialId: data.material.value,
        qty: data.qty,
      };
      await addMaterial(payload);
      console.log(payload, "zxcvbsdfgh");
      unSetDialog("add_material");
      // refreshPage();
      dispatch(showSuccessSnackbar("Material added Successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to add Material"));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <SearchableAutoComplete
                name="material"
                label={t("materials")}
                control={control}
                data={inventoryManagementOptions}
                payload={inventoryManagementPayload}
                setPayload={setinventoryManagementPayload}
                loading={false}
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></SearchableAutoComplete>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label="Unit of Measurement"
                name="uom"
                control={control}
                disabled={true}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                name="qty"
                label="Quantity"
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Save
            </Button>

            <Button variant="text" onClick={() => unSetDialog("add_material")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddMaterial;
