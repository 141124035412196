import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../../../contexts/DialogContext";
import { useTranslation } from "react-i18next";
import EditDelete from "../../../../../components/EditDelete";

import {
  Card,
  Grid,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../../../components/Toolbar";
import useWindowDimensions from "../../../../../utils/windowDimensions";
import useMainContentDimensions from "../../../../../utils/mainContentDimensions";
import useUser from "../../../../../hooks/useUser";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function CustomDataGrid(props) {
  const { width: mainContentWidth } = useMainContentDimensions();

  const { state } = useLocation();
  const { t } = useTranslation();
  const {
    addPage,
    setSelectedRows,
    editPage,
    rowCount,
    // refreshPage,
    setFilterResetFunc,
    isLoading,
    paginationModel,
    paginationMeta,
    paging,
    onPaginationModelChange,
  } = props;

  const currentPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllMaterial, materials, id, downloadFile, isLoadingDownload, downloadFileType } =
    useUser();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const handleSelectedRows = (GridSelectionModelChangeParams) => {
    let selectedValues = [];
    if (Array.isArray(GridSelectionModelChangeParams)) {
      GridSelectionModelChangeParams.forEach((selection_index) => {
        const filteredValues = materials.filter(function (data) {
          return data.tenantId === selection_index;
        });
        selectedValues = [...selectedValues, ...filteredValues];
      });
    }
    setSelectedRows(selectedValues);
  };

  console.log(rowCount, "loading child component");
  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete rowClick={onClick} editPage={() => editPage(params.id)} menus={["edit"]} />
        );
      },
    },
    {
      field: "material",
      headerName: "Material Name",
      valueGetter: (params) => params.materialName,
      width: 180,
    },
    {
      field: "qty",
      headerName: "Quantity",
      width: 180,
    },
    {
      field: "uom",
      headerName: "Unit of Measurements",
      width: 180,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            addPage={addPage}
            editPage={editPage}
            downloadFile={downloadFile}
            // refreshPage={refreshPage}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={t("materials")}
        downloadFile={downloadFile}
      />{" "}
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          checkboxSelection
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          rowCount={rowCount}
          getRowId={(row) => row["id"]}
          columns={columns}
          rows={materials}
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMeta={paginationMeta}
          loading={isLoading}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
        />
      </CardContent>
    </Card>
  );
}

export default CustomDataGrid;
