import React, { useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SunEditorWrapper from "../../../../components/SunEditorWrapper";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { config } from "@fullcalendar/core";

const CompanyDetail = (props) => {
  const { fields, defaultContentValue } = props;
  console.log(defaultContentValue, "defaultContentValue");
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container spacing={12} className="row">
      <Grid item md={6}>
        <CustomTextField
          label="Template Name"
          name="emailTemplateName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label="Template Code"
          name="emailTemplateCode"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={12}>
        <CustomTextField
          label="Subject"
          name="subject"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={12}>
        <SunEditorWrapper name="content" defaultValue={defaultContentValue} />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default CompanyDetail;
