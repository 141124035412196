import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useAuth from "../../../../hooks/useAuth";
const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const BasicDetail = (props) => {
  const { user } = useAuth();
  const { validationFields } = props;
  const [tenantPayload, setTenantPayload] = useState(currentTenantPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  useEffect(() => {
    if (user && (user.businessType == "SUPER_ADMIN" || user.businessType == "DISTRIBUTOR")) {
      fetchTenants();
    }
  }, [tenantPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Grid container spacing={12} className="row" pt={8}>
      {user && (user.businessType === "SUPER_ADMIN" || user.businessType == "DISTRIBUTOR") && (
        <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
          <SearchableAutoComplete
            control={control}
            data={tenantOptions}
            label="Select Tenant"
            payload={tenantPayload}
            setPayload={setTenantPayload}
            loading={false}
            name="tenant"
            multiple={false}
            defaultValue={""}
            hasNextPage={false}
            fields={validationFields}
          />
        </Grid>
      )}
      <Grid item md={6}>
        <CustomTextField
          label="Branch Name"
          name="branchName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label="Status"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default BasicDetail;
