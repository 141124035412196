import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      country_name: "Country Name",
      country_code: "Country Code",
      add_country: "Add Country",
      edit_country: "Edit Country",
      country_title: "Country",
      country_title_caption: "The list of countries",
      masters: "Masters",
      Refresh: "Refresh",
      Excel: "Excel",
      Csv: "Csv",
      Pdf: "Pdf",
      Print: "Print",
      Save: "Save",
      Cancel: "Cancel",
      Reset: "Reset",
      country_list: "Country List",
      state_name: "State Name",
      state_code: "State Code",
      add_state: "Add State",
      edit_state: "Edit State",
      state_title: "State",
      state_title_caption: "The list of States",
      state_list: "State List",
      district_name: "District Name",
      district_code: "District Code",
      add_district: "Add District",
      edit_district: "Edit District",
      district_title: "District",
      district_title_caption: "The list of district",
      district_list: "District List",
      taluk_name: "Taluk Name",
      taluk_code: "Taluk Code",
      add_taluk: "Add Taluk",
      edit_taluk: "Edit Taluk",
      taluk_title: "Taluk",
      taluk_title_caption: "The list of Taluk",
      taluk_list: "Taluk List",
      city_name: "City Name",
      city_code: "City Code",
      add_city: "Add City",
      edit_city: "Edit City",
      city_title: "City",
      city_title_caption: "The list of city",
      city_list: "City List",
      village_name: "Village Name",
      village_code: "Village Code",
      add_village: "Add Village",
      edit_village: "Edit Village",
      village_title: "Village",
      village_title_caption: "The list of village",
      village_list: "Village List",
      pincode_name: "Pincode",
      pincode_code: "Pincode Code",
      add_pincode: "Add Pincode",
      edit_pincode: "Edit Pincode",
      pincode_title: "Pincode",
      pincode_title_caption: "The list of pincode",
      pincode_list: "Pincode List",
      nas_name: "Nas Ip Address",
      short_name: "Short Name",
      type_name: "Type",
      description_name: "Description",
      secret_name: "Secret",
      add_nas: "Add Nas",
      edit_nas: "Edit Nas",
      nas_title: "Nas",
      nas_title_caption: "The list of nas",
      nas_list: "Nas List",
      ippool_name: "Ip Pool",
      from_ip: "From IP",
      to_ip: "To IP",
      ip_comments: "Comments",
      add_ippool: "Add Ip Pool",
      edit_ippool: "Edit Ip Pool",
      ippool_title: "Ip Pool",
      ippool_title_caption: "The list of ip pool",
      ippool_list: "Ip Pool List",
      plan_name: "Plan Name",
      policy_code: "Policy Code",
      add_plan: "Add Plan",
      edit_plan: "Edit Plan",
      policy_title: "Policy",
      policy_title_caption: "The list of Policy",
      plan_list: "Plan List",
      addon_name: "Add On",
      addon_code: "AddOn Code",
      add_addon: "Add AddOn",
      edit_addon: "Edit AddOn",
      addon_title: "AddOn",
      addon_title_caption: "The list of AddOn",
      addon_list: "AddOn List",
      company_name: "Company",
      company_code: "Company Code",
      add_company: "Add Company",
      edit_company: "Edit Company",
      company_title: "Company",
      company_title_caption: "The list of Company",
      company_list: "Company List",
      tenant_name: "Tenant",
      tenant_code: "Tenant Code",
      add_tenant: "Add Tenant",
      edit_tenant: "Edit Tenant",
      tenant_title: "Tenant",
      tenant_title_caption: "The list of Tenant",
      tenant_list: "Tenant List",
      branch_name: "Branch",
      branch_code: "Branch Code",
      add_branch: "Add Branch",
      edit_branch: "Edit Branch",
      branch_title: "BRanch",
      branch_title_caption: "The list of Branch",
      branch_list: "Branch List",
      department_name: "Department",
      department_code: "Department Code",
      add_department: "Add Department",
      edit_department: "Edit Department",
      department_title: "Department",
      department_title_caption: "The list of Department",
      department_list: "Department List",
      emailmaster_name: "Email Master",
      emailmaster_code: "Email Master Code",
      add_emailtemplate: "Add Email Template",
      edit_emailtemplate: "Edit Email Template",
      emailmaster_title: "Email Master",
      emailmaster_title_caption: "The list of Email Master",
      emailtemplate_list: "Email Template List",
      smstemplate_name: "Sms Template",
      smstemplate_code: "Sms Template Code",
      add_smstemplate: "Add Sms Template",
      edit_smstemplate: "Edit Sms Template",
      smstemplate_title: "Sms Template",
      smstemplate_title_caption: "The list of Sms Template",
      smstemplate_list: "Sms Template List",
      wallet_name: "Wallet",
      wallet_code: "Wallet Code",
      add_wallet: "Add Wallet",
      edit_wallet: "Edit Wallet",
      wallet_title: "Wallet",
      wallet_title_caption: "The list of Wallet",
      wallet_list: "Wallet List",
      user_name: "User Name",
      user_code: "User Code",
      add_user: "Add User",
      edit_user: "Edit User",
      user_title: "User",
      user_title_caption: "The list of Users",
      user_list: "User List",
      smsapi_name: "Sms Api Name",
      smsapi_code: "Sms Api Code",
      add_smsapi: "Add Sms Api",
      edit_smsapi: "Edit Sms Api",
      smsapi_title: "Sms Api",
      smsapi_title_caption: "The list of Sms Apis",
      smsapi_list: "Sms Api List",
      whatsapp_name: "Whatsapp Api Name",
      whatsapp_code: "Whatsapp Api Code",
      add_whatsapp: "Add Whatsapp Api",
      edit_whatsapp: "Edit Whatsapp Api",
      whatsapp_title: "Whatsapp Api",
      whatsapp_title_caption: "The list of Whatsapp Apis",
      whatsapp_list: "Whatsapp Api List",
      upload_speed: "Upload Speed(In Mbps)",
      download_speed: "Download Speed(In Mbps)",
      plan_type: "Plan Type",
      download_limit: "Download Limit(In Mb)",
      upload_limit: "Upload Limit(In Mb)",
      plan_data_type: "Plan Data Type",
      rate: "Rate",
      time_unit: "Time Unit",
      unit_type: "Unit Type",
      dashboard_title: "Dashboard",
      complaint_type_name: "Complaint Type Name",
      comments: "Comments",
      add_complaint_type: "Add Complaint Type",
      add_complaint: "Add Complaint",
      complaint_type: " Complaint Type",
      complaint_type_list: "Complaint Type List",
      complaint_list: "Complaint List",
      customer_user_name: "Customer User Name",
      customer_name: "Customer Name",
      mobile_number: "Mobile Number",
      ip_address: "Ip Address",
      first_name: "First Name",
      last_name: "Last Name",
      assign_to_employee: "Assign To Employee",
      prioriy: "Prioriy",
      subject: "Subject",
      notes: "Notes",
      closed_complaints: "Closed Complaints",
      edit_complaint: "Edit Complaint",
      lead_list: "Lead List",
      add_lead: "Add Lead",
      lead_stats: "Lead Stats",
      lead_type: "Lead Type",
      address: "Address",
      email_id: "Email Id",
      edit_complaint_type: "Edit Complaint Type",
      edit_lead: "Edit Lead",
      lead_details: "Lead Details",
      complaint_details: "Complaint Details",
      lead_closed: "Lead Closed",
      complaint_closed: "Complaint Closed",
      complaint_stats: "Complaint Stats",
      template_name: " Template Name",
      template_code: "Template Code",
      content: "Content",
      priority: "priority",
      general_settings: "General Settings",
      email_settings: "Email Settings",
      sms_settings: "Sms Settings",
      sms_logs: "Sms Logs",
      email_logs: "Email Logs",
      unpaid_invoices: "Unpaid Invoices",
      paid_invoices: "Paid Invoices",
      login_history: "Login History",
      user_activities: "User Activities",
      email_to_tenant: "Email To Tenant",
      email_to_user: "Email To User",
      sms_to_tenant: "Sms To Tenant",
      sms_to_users: "Sms To Users",
      sms_to_employee: "Sms To Employee",
      edit_employee: "Edit Employee ",
      add_employee: "Add Employee",
      reset: "Reset",
      short_name: "Short Name",
      from_ip: "From Ip",
      full_name: "Full Name",
      complaint_no: "Complaint No",
      module_name: "Module Name",
      action_type: "Action Type",
      login_ip: "Login Ip",
      balance_credit: "Balance & Credit",
      renew_history: "Renew History",
      plan_history: "Plan History",
      kyc_documents: "Kyc Documents",
      traffic_report: "Traffic Report",
      proforma_invoices: "Proforma Invoices",
      session_history: "Session History",
      cancelled_invoices: "Cancelled Invoices",
      tax_type: "Tax Type",
      to_ip: "To Ip",
      session_history: "Session History",
      gst_invoices: "Gst Invoices",
      authentication_logs: "Authentication Logs",
      card_list: "Card List",
      online_cards: "Online Cards",
      prepaid_cards: "Prepaid Cards",
      refill_cards: "Refill Cards",
      card_stats: "Card Stats",
      card_session_history: "Card Session History",
      add_generate_card: "Add Generate Card",
      materials_list: "Materials List",
      product_name: "Product Name",
      unit_of_measurement: "Unit of Measurement",
      add_materials: "Add Materials",
      aadhar_card: "Aadhar Card",
      hotel_user_list: "Hotel Users List",
      add_hotel_user: "Add Hotel User",
      name: "Name",
      edit_material: "Edit Material",
      edit_hotel_user: "Edit Hotel User",
      activate_users: "Activate Users",
      deactivate_users: "Deactivate Users",
      dst_address: "Dst.Address",
      gateway: "Gateway",
      routes_list: "Routes List",
      add_routes: "Add Routes",
      edit_routes: "Edit Routes",
      pool_list: "Pool List",
      add_pool: "Add Pool",
      edit_pool: "Edit Pool",
      pool_list: "Pool List",
      interface: "Interface",
      add_addresses: "Add Addresses",
      edit_addresses: "Edit Addresses",
      addresses_list: "Addresses List",
      materials: "Materials",
      address_name: "Address Name",
      interfaces_name: "Interface Name",
      add_mk_radius: "Add Radius",
      radius_list: "Radius List",
      edit_mk_radius: "Edit Radius",
      add_pppProfile: "Add PPP Profile",
      edit_pppProfile: "Edit PPP Profile",
      list_pppProfile: "List PPP Profile",
      add_pppSecret: "Add PPP Secret",
      edit_pppSecret: "Edit PPP Secret",
      list_pppSecret: "List PPP Secret",
      add_pppServer: "Add PPP Server",
      edit_pppServer: "Edit PPP Server",
      list_pppServer: "List PPP Server",
      local_address: "Local Address",
      nas: "Nas",
      remote_address: "Remote Address",
      interim_update: "Interim Update",
      service_name: "Service Name",
      max_mtu: "Max MTU",
      max_mru: "Max MRU",
      mrru: "MRRU",
      keepalive_timeout: "Keepalive Timeout",
      default_profile: "Default Profile",
      courier_name: "Courier Name",
      courier_type: "Courier Type",
      reseller_name: "Reseller Name",
      docket_number: "Docket Number",
      ag_tracker_details: "Agreement Tracker Details",
      agreement_tracker_list: "List Agreement Tracker",
      add_agreement_tracker: "Add Agreement Tracker",
      schedule_user_list: "List Schedule User",
      balance_statement: "Balance Statement",
      region: "Region",
      material_report: "Material Reports",
      addresses: "Addresses",
      dstAddresses: "Dst Address",
      wallet_entries: "Wallet Entries",
      hotel_activation_report: "Hotel Activation Report",
      nas_group_list: "Nas Group List",
      plan_group_list: "Plan Group List",
      employee_list: "Employee List",
      active_users: "Active Users",
      online_users: "Online Users",
      expired_users: "Expired Users",
      renewal_reports: "Renewal Reports",
      due_reports: "Due Reports",
      sharing_reports: "Sharing Reports",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bon retour",
      "We've missed you": "Tu nous as manqué",
      country_name: "De campagne Nom",
      country_code: "Code postal",
      country_title: "De campagne",
      add_country: "Ajouter un pays",
      edit_country: "Modifier le pays",
      country_title_caption: "La liste des pays",
      masters: "Maîtrise",
      Refresh: "Rafraîchir",
      Excel: "Exceller",
      Csv: "Csv",
      Pdf: "Pdf",
      Print: "Impression",
      Save: "Sauvegarder",
      Cancel: "Annuler",
      Reset: "Réinitialiser",
      country_list: "Liste des pays",
      state_name: "Nom d'état",
      state_code: "Indicatif d'état",
      add_state: "Ajouter un état",
      edit_state: "Modifier l'état",
      state_title: "État",
      state_title_caption: "La liste des États",
      state_list: "Liste d'état",
      district_name: "Nom du district",
      district_code: "Indicatif de district",
      add_district: "Ajouter un quartier",
      edit_district: "Modifier le quartier",
      district_title: "District",
      district_title_caption: "La liste des quartiers",
      district_list: "Liste des districts",
      taluk_name: "Nom Taluk",
      taluk_code: "Taluk Code",
      add_taluk: "Ajouter Taluk",
      edit_taluk: "Modifier Taluk",
      taluk_title: "Taluk",
      taluk_title_caption: "La liste de Taluk",
      taluk_list: "Liste Taluk",
      city_name: "Nom de la zone",
      city_code: "Indicatif régional",
      add_city: "Ajouter une zone",
      edit_city: "Modifier la zone",
      city_title: "Région",
      city_title_caption: "La liste des domaines",
      city_list: "Liste des zones",
      village_name: "Nom de la sous-zone",
      village_code: "Indicatif de sous-région",
      add_village: "Ajouter une sous-zone",
      edit_subarea: "Modifier la sous-zone",
      village_title: "Sous-zone",
      village_title_caption: "La liste des sous-zones",
      village_list: "Liste des sous-zones",
      pincode_name: "Code PIN",
      pincode_code: "Code PIN",
      add_pincode: "Ajouter un code PIN",
      edit_pincode: "Modifier le code PIN",
      pincode_title: "Code PIN",
      pincode_title_caption: "La liste des codes PIN",
      pincode_list: "Liste des codes PIN",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
      "We've missed you": "Wir haben dich vermisst",
      country_name: "Ländername",
      country_code: "Landesvorwahl",
      add_country: "Land hinzufügen",
      edit_country: "Land bearbeiten",
      country_title: "Land",
      country_title_caption: "Die Liste der Länder",
      masters: "Meisterinnen",
      Refresh: "Aktualisierung",
      Excel: "Excel",
      Csv: "Csv",
      Pdf: "Pdf",
      Print: "Drucken",
      Save: "Speichern",
      Cancel: "Abbrechen",
      Reset: "Zurücksetzen",
      country_list: "Länderliste",
      state_name: "Staatsname",
      state_code: "Staatskodex",
      add_state: "Staat hinzufügen",
      edit_state: "Status bearbeiten",
      state_title: "Bundesland",
      state_title_caption: "Die Liste der Staaten",
      state_list: "Staatliche Liste",
      district_name: "Bezirksbezeichnung",
      district_code: "Distriktcode",
      add_district: "Bezirk hinzufügen",
      edit_district: "Bezirk bearbeiten",
      district_title: "Kreis",
      district_title_caption: "Die Bezirksliste",
      district_list: "Bezirksliste",
      taluk_name: "Taluk Name",
      taluk_code: "Taluk Code",
      add_taluk: "Füge Taluk hinzu",
      edit_taluk: "Taluk bearbeiten",
      taluk_title: "Taluk",
      taluk_title_caption: "Die Liste von Taluk",
      taluk_list: "Taluk-Liste",
      city_name: "Bereichsname",
      city_code: "Vorwahl",
      add_city: "Bereich hinzufügen",
      edit_city: "Bereich bearbeiten",
      city_title: "Bereich",
      city_title_caption: "Die Gebietsliste",
      city_list: "Gebietsliste",
      village_name: "Unterbereichsname",
      village_code: "SubArea Code",
      add_village: "Unterbereich hinzufügen",
      edit_village: "Unterbereich bearbeiten",
      village_title: "Unterbereich",
      village_title_caption: "Die Liste der Unterbereiche",
      village_list: "Unterbereichsliste",
      pincode_name: "Geheimzahl",
      pincode_code: "Pincode Code",
      add_pincode: "Pincode hinzufügen",
      edit_pincode: "PIN-Code bearbeiten",
      pincode_title: "Geheimzahl",
      pincode_title_caption: "Die Liste der PIN-Codes",
      pincode_list: "PIN-Code-Liste",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
      "We've missed you": "We hebben je gemist",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
