import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useDispatch } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SaveIcon from "@mui/icons-material/Save";

import {
  Grid,
  Divider as MuiDivider,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useForm, FormProvider } from "react-hook-form";
import useHotelManagement from "../../../../hooks/useHotelManagement";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import usePlanGroup from "../../../../hooks/usePlanGroup";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { useDialog } from "../../../../contexts/DialogContext";
import CustomTextField from "../../../../components/CustomTextField";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
const Divider = styled(MuiDivider)(spacing);

const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("Required"),
  password: Yup.string().max(255).required("Password is required"),
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  activationDate: Yup.string().required("Required"),
  simUse: Yup.string().required("Required"),
});

function ActivateUser(props) {
  const { selectedRows, refreshPage } = props;
  const { activateUser, isRenewSubmitting } = useHotelManagement();
  const dispatch = useDispatch();
  const { unSetDialog, openDialog } = useDialog();

  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();
  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  const fetchPlanGroupPlans = async () => {
    await autoSearchPlanGroupPlan(planGroupPlanPayload);
  };

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  useEffect(() => {
    let userNames = [];
    for (const index in selectedRows) {
      userNames.push(selectedRows[index]["userName"]);
    }
    setValue("userName", userNames.join(","));
  }, [selectedRows]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  const onSubmit = async (data) => {
    try {
      if (selectedRows.length == 0) {
        return dispatch(showErrorSnackbar("Please select the user"));
      }
      let resultData = [];
      for (const index in selectedRows) {
        resultData.push({
          radUserId: selectedRows[index]["radUserId"],
          userName: selectedRows[index]["userName"],
          activationDateStr: data.activationDateStr,
          password: data.password,
          plan: {
            planId: data.plan.value,
            planName: data.plan.label,
          },
          simUse: data.simUse,
        });
      }
      await activateUser(resultData);
      dispatch(showSuccessSnackbar("User Activated Successfully!"));
      unSetDialog("activate_user");
      refreshPage();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to Activate user"));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomTextField
                name="userName"
                label="User Name"
                control={control}
                fields={validationSchema.fields}
                disabled={true}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label="Password"
                name="password"
                type="password"
                my={2}
                control={control}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <SearchableAutoComplete
                control={control}
                data={planGroupPlanOptions}
                label="Plan"
                payload={planGroupPlanPayload}
                setPayload={setPlanGroupPlanPayload}
                loading={false}
                name="plan"
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomDatePicker
                name="activationDate"
                nameTemp="activationDateStr"
                label="No of Days"
                range={false}
                control={control}
                fields={validationSchema.fields}
                timePicker={true}
                format="DD-MM-YYYY HH:mm:ss"
                conversionFormat="YYYY-MM-DD HH:mm:ss"
                minDate={new Date()}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                name="simUse"
                label="Sim Use"
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Save
            </Button>

            <Button variant="text" onClick={() => unSetDialog("activate_user")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default ActivateUser;
