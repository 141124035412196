import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert as MuiAlert,
  Button,
  DialogActions,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDialog } from "../../../../contexts/DialogContext";
import useNas from "../../../../hooks/useNas";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useNasGroup from "../../../../hooks/useNasGroup";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import CustomTextField from "../../../../components/CustomTextField";

const initialValues = {
  nas: [],
};

const validationSchema = Yup.object().shape({
  nas: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      }),
    )
    .min(1, "You need at least one nas")
    .required("Please select the nas"),
});

const Alert = styled(MuiAlert)(spacing);

const MapNasModal = ({ nasGroupId, isNew }) => {
  const { unSetDialog } = useDialog();

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { autoSearchNas, nasAutoSearchResults, autoSearchLoading } = useNas();
  const { addNasGroupMap, isSubmitting, getNasGroupMaps } = useNasGroup();
  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [payload, setPayload] = useState(currentPayload);
  const [modalNew, setModalNew] = useState(isNew);
  const [nasGroupMapPayload, setNasGroupMapPayload] = useState({
    filters: [
      {
        fieldName: "nasGroupId",
        fieldValue: nasGroupId,
        op: "eq",
        isJoin: true,
        dataType: "long",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  });

  const [nasOptions, setNasOptions] = useState([]);

  const fetchData = async () => {
    await autoSearchNas(payload);
  };

  useEffect(() => {
    if (nasAutoSearchResults.data.length !== 0) {
      setNasOptions([...nasOptions, ...nasAutoSearchResults.data]);
    }
  }, [nasAutoSearchResults]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const nasDetails = [];
      const nases = data.nas;

      for (let i = 0; i < nases.length; i++) {
        nasDetails.push({
          nasGroup: {
            nasGroupId: nasGroupId,
          },
          nas: {
            nasId: nases[i]["value"],
            nasName: nases[i]["label"],
          },
        });
      }

      console.log(nasDetails, "nasDetails");

      await addNasGroupMap(nasDetails);
      await getNasGroupMaps(nasGroupMapPayload);
      reset();
      dispatch(showSuccessSnackbar("Nas mapped successfully!"));
      unSetDialog("map_nas_modal");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };

  useEffect(() => {
    if (modalNew) {
      setNasOptions([]);
      setModalNew(false);
    }
  }, [modalNew]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 5 }}>
            <Grid container spacing={12} className="row" pt={8}>
              <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
                <SearchableAutoComplete
                  control={control}
                  data={nasOptions}
                  label="Choose a Nas"
                  payload={payload}
                  setPayload={setPayload}
                  loading={autoSearchLoading}
                  name="nas"
                  multiple={true}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Add
            </Button>

            <Button variant="text" onClick={() => unSetDialog("map_nas_modal")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default MapNasModal;
