import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  ListItemText as MuiListItemText,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";

const ListItemText = styled(MuiListItemText)(spacing);

const EditDelete = (props) => {
  const {
    editPage,
    rowId,
    menus,
    renewClick,
    assignClick,
    collectClick,
    cancelClick,
    clickIcon,
    clickScheduleIcon,
  } = props;
  console.log(props, "menusmenus");
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemClick = (actionType) => {
    console.log(rowId, "rowIdrowIdrowId");
    editPage(rowId, actionType);
    handleClose();
  };

  const menuItemRenewClick = () => {
    renewClick(rowId);
    handleClose();
  };

  const menuItemAssignClick = () => {
    assignClick(rowId);
    handleClose();
  };

  const menuItemCollectClick = () => {
    collectClick(rowId);
    handleClose();
  };

  const menuItemCancelClick = () => {
    cancelClick(rowId);
    handleClose();
  };

  const menuItemNewClick = (actionType) => {
    console.log(actionType, "actionTypeactionType");
    clickIcon(rowId, actionType);
    handleClose();
  };

  const scheduleClick = () => {
    clickScheduleIcon(rowId);
    handleClose();
  };

  const editMenu = () => {};

  return (
    <div mt={3}>
      <IconButton
        aria-owns={anchorEl ? "icon-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="secondary"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="icon-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menus && menus.includes("edit") && (
          <MenuItem onClick={() => menuItemClick("edit")}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={t("Edit")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("cancel_schedule") && (
          <MenuItem onClick={() => scheduleClick("cancel_schedule")}>
            <ListItemIcon>
              <UpdateDisabledIcon />
            </ListItemIcon>
            <ListItemText primary={t("Cancel Schedule")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("renew") && (
          <MenuItem onClick={menuItemRenewClick}>
            <ListItemIcon>
              <ReplayIcon />
            </ListItemIcon>
            <ListItemText primary={t("Renew")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("assign_plan") && (
          <MenuItem onClick={menuItemAssignClick}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText primary={t("Assign Plan")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("collect") && (
          <MenuItem onClick={menuItemCollectClick}>
            <ListItemIcon>
              <AutoAwesomeMotionIcon />
            </ListItemIcon>
            <ListItemText primary={t("Collect")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("cancel") && (
          <MenuItem onClick={menuItemCancelClick}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <ListItemText primary={t("Cancel")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("delete") && (
          <MenuItem>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={t("Delete")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("invoice_view") && (
          <MenuItem onClick={menuItemNewClick}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText primary={t("View")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("general_settings") && (
          <MenuItem onClick={() => menuItemClick("general_settings")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t("general_settings")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("email_settings") && (
          <MenuItem onClick={() => menuItemClick("email_settings")}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={t("email_settings")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("sms_settings") && (
          <MenuItem onClick={() => menuItemClick("sms_settings")}>
            <ListItemIcon>
              <SmsIcon />
            </ListItemIcon>
            <ListItemText primary={t("sms_settings")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("hotel_activation") && (
          <MenuItem onClick={() => menuItemNewClick("Activate User")}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={t("Activate")} pl={0} />
          </MenuItem>
        )}
        {menus && menus.includes("session_users") && (
          <MenuItem onClick={() => menuItemNewClick("Show Sessions")}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText primary={t("Show Sessions")} pl={0} />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
EditDelete.propTypes = {
  editPage: PropTypes.func,
  clickScheduleIcon: PropTypes.func,
};

export default EditDelete;
