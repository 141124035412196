import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useTenant from "../../../../hooks/useTenant";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    tenantNameOperator: "eq",
    statusOperator: "eq",
    tenantName: [],
    status: [],
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchTenant, tenantAutoSearchResults, isLoadingAutoSearch } = useTenant();

  const [tenantPayload, setTenantPayload] = useState(currentPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenantMultiValue, setTenantMultiValue] = useState(false);
  const [statusMultiValue, setStatusMultiValue] = useState(false);
  const [defaultTenant, setDefaultTenant] = useState([]);

  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const tenantOpValue = watch("tenantNameOperator");

  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.tenantName != null && Object.keys(data.tenantName).length != 0) {
        filters.push({
          fieldName: "tenantId",
          fieldValue: convertedValue(data.tenantName, "value").join(","),
          op: data.tenantNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.status != null && Object.keys(data.status).length != 0) {
        filters.push({
          fieldName: "status",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.error(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (tenantOpValue == "in" || tenantOpValue == "nin") {
      setDefaultTenant([]);
      setValue("tenantName", []);
      setTenantMultiValue(true);
    } else {
      setDefaultTenant({});
      setValue("tenantName", {});
      setTenantMultiValue(false);
    }
  }, [tenantOpValue]);

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  const fetchTenant = async () => {
    await autoSearchTenant(tenantPayload);
  };
  useEffect(() => {
    fetchTenant();
  }, [tenantPayload]);

  useEffect(() => {
    if (tenantAutoSearchResults.data.length !== 0) {
      setTenantOptions([...tenantOptions, ...tenantAutoSearchResults.data]);
    }
  }, [tenantAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="tenantNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={tenantOptions}
                label="Tenant Name"
                payload={tenantPayload}
                setPayload={setTenantPayload}
                loading={isLoadingAutoSearch}
                name="tenantName"
                multiple={tenantMultiValue}
                defaultValue={defaultTenant}
              />
            </Grid>

            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={statusOptions}
                label="Status"
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
