import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import InventoryManagementReducer from "../reducers/InventoryManagementReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { InventoryManagementConstants } from "../constants/inventorymanagement.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  inventoryManagements: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  inventoryManagementSearchResults: [],
  inventoryManagement: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  inventoryManagementAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const InventoryManagementContext = createContext({
  ...initialState,
  getAllInventoryManagement: () => {},
  addInventoryManagement: () => {},
  searchInventoryManagement: () => {},
  autoSearchInventoryManagement: () => {},
  getInventoryManagement: () => {},
  updateInventoryManagement: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function InventoryManagementProvider({ children }) {
  const [state, dispatch] = useReducer(InventoryManagementReducer, initialState);

  const getAllInventoryManagement = async (payload) => {
    try {
      dispatch({
        type: InventoryManagementConstants.FETCH_INVENTORY_MANAGEMENT_REQUEST,
      });
      const response = await axios.post("/material/list", payload);
      dispatch({
        type: InventoryManagementConstants.FETCH_INVENTORY_MANAGEMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: InventoryManagementConstants.FETCH_INVENTORY_MANAGEMENT_FAILURE,
      });
    }
  };
  const searchInventoryManagement = async (payload) => {
    try {
      const response = await axios.post("/material/search", payload);
      dispatch({
        type: InventoryManagementConstants.INVENTORY_MANAGEMENT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: InventoryManagementConstants.INVENTORY_MANAGEMENT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: InventoryManagementConstants.INVENTORY_MANAGEMENT_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchInventoryManagement = async (payload) => {
    try {
      const response = await axios.post("material/auto-search", payload);
      dispatch({
        type: InventoryManagementConstants.INVENTORY_MANAGEMENT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: InventoryManagementConstants.INVENTORY_MANAGEMENT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: InventoryManagementConstants.INVENTORY_MANAGEMENT_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addInventoryManagement = async (payload) => {
    const response = await axios.post("/material/add", payload);
    dispatch({
      type: InventoryManagementConstants.ADD_INVENTORY_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: InventoryManagementConstants.ADD_INVENTORY_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const getInventoryManagement = async (id) => {
    const response = await axios.get("/material/" + id);
    dispatch({
      type: InventoryManagementConstants.GET_INVENTORY_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: InventoryManagementConstants.GET_INVENTORY_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const updateInventoryManagement = async (payload) => {
    const response = await axios.put("/material/update/" + payload.materialId, payload);
    dispatch({
      type: InventoryManagementConstants.UPDATE_INVENTORY_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: InventoryManagementConstants.UPDATE_INVENTORY_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/material/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "inventoryManagement." + fileType);
    dispatch({
      type: InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/master/inventoryManagement/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_inventoryManagement." + fileType);
    dispatch({
      type: InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_REQUEST,
    });
    dispatch({
      type: InventoryManagementConstants.DOWNLOAD_INVENTORY_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: InventoryManagementConstants.UPLOAD_INVENTORY_MANAGEMENT_REQUEST,
      });
      const response = await axios({
        url: "/master/inventoryManagement/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: InventoryManagementConstants.UPLOAD_INVENTORY_MANAGEMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: InventoryManagementConstants.UPLOAD_INVENTORY_MANAGEMENT_FAILURE,
      });
    }
  };

  return (
    <InventoryManagementContext.Provider
      value={{
        ...state,
        getAllInventoryManagement,
        addInventoryManagement,
        searchInventoryManagement,
        autoSearchInventoryManagement,
        getInventoryManagement,
        updateInventoryManagement,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </InventoryManagementContext.Provider>
  );
}

export { InventoryManagementContext, InventoryManagementProvider };
