import { TenantConstants } from "../constants/tenant.constants";

const TenantReducer = (state, action) => {
  switch (action.type) {
    case TenantConstants.FETCH_TENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.FETCH_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenants: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantConstants.FETCH_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.TENANT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.TENANT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSearchResults: action.payload,
      };
    case TenantConstants.TENANT_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.ADD_TENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.ADD_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TenantConstants.ADD_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case TenantConstants.GET_TENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.GET_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenant: action.payload,
      };
    case TenantConstants.GET_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.GET_SHOW_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.GET_SHOW_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showPass: action.payload,
      };
    case TenantConstants.GET_SHOW_PASSWORD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.UPDATE_TENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenant: action.payload,
      };
    case TenantConstants.UPDATE_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case TenantConstants.DOWNLOAD_TENANT_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case TenantConstants.DOWNLOAD_TENANT_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };
    case TenantConstants.DOWNLOAD_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case TenantConstants.UPLOAD_TENANT_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case TenantConstants.UPLOAD_TENANT_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case TenantConstants.UPLOAD_TENANT_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case TenantConstants.UPLOAD_INVOICE_LOGO_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case TenantConstants.UPLOAD_INVOICE_LOGO_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case TenantConstants.UPLOAD_INVOICE_LOGO_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case TenantConstants.ADD_TENANT_PLAN_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TenantConstants.ADD_TENANT_PLAN_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case TenantConstants.ADD_TENANT_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case TenantConstants.FETCH_TENANT_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.FETCH_TENANT_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantPlans: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantConstants.FETCH_TENANT_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.FETCH_TENANT_IPTV_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.FETCH_TENANT_IPTV_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantIpTvPlans: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantConstants.FETCH_TENANT_IPTV_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.FETCH_TENANT_IPPOOL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.FETCH_TENANT_IPPOOL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantIppools: action.payload.data,
        paging: action.payload.paging,
      };
    case TenantConstants.FETCH_TENANT_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.ADD_TENANT_IPPOOL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TenantConstants.ADD_TENANT_IPPOOL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case TenantConstants.ADD_TENANT_IPPOOL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case TenantConstants.TENANT_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isAutoSearchLoading: true,
        hasError: false,
      };
    case TenantConstants.TENANT_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isAutoSearchLoading: false,
        tenantAutoSearchResults: action.payload,
      };
    case TenantConstants.TENANT_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isAutoSearchLoading: false,
        errorMessage: action.payload.message,
      };

    case TenantConstants.TENANT_PLAN_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isTenantAutoSearchLoading: true,
        hasError: false,
      };
    case TenantConstants.TENANT_PLAN_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isTenantAutoSearchLoading: false,
        planAutoSearchResults: action.payload,
      };
    case TenantConstants.TENANT_PLAN_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isTenantAutoSearchLoading: false,
        errorMessage: action.payload.message,
      };

    case TenantConstants.TENANT_IPTV_PLAN_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isIpTvTenantAutoSearchLoading: true,
        hasError: false,
      };
    case TenantConstants.TENANT_IPTV_PLAN_AUTO_SEARCH_SUCCESS:
      console.log(action.payload, "111111111111111");
      return {
        ...state,
        isIpTvTenantAutoSearchLoading: false,
        ipTvPolicyAutoSearchResults: action.payload,
      };
    case TenantConstants.TENANT_IPTV_PLAN_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isIpTvTenantAutoSearchLoading: false,
        errorMessage: action.payload.message,
      };

    case TenantConstants.TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isTenantAutoSearchLoading: true,
        hasError: false,
      };
    case TenantConstants.TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isTenantAutoSearchLoading: false,
        planGroupPlanAutoSearchResults: action.payload,
      };
    case TenantConstants.TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isTenantAutoSearchLoading: false,
        errorMessage: action.payload,
      };

    case TenantConstants.GET_PAYMENT_GATEWAY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.GET_PAYMENT_GATEWAY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentGatewayKeyId: action.payload,
      };
    case TenantConstants.GET_PAYMENT_GATEWAY_ID_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.CREATE_TENANT_ORDER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case TenantConstants.CREATE_TENANT_ORDER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        tenantOrderId: action.payload,
      };
    case TenantConstants.CREATE_TENANT_ORDER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case TenantConstants.GET_TENANT_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.GET_TENANT_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tenantSetting: action.payload,
      };
    case TenantConstants.GET_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case TenantConstants.CANCEL_TENANT_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case TenantConstants.CANCEL_TENANT_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TenantConstants.CANCEL_TENANT_ORDER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default TenantReducer;
