import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useDistrict from "../../../../hooks/useDistrict";
import useStateMaster from "../../../../hooks/useStateMaster";

import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    districtNameOperator: "eq",
    stateNameOperator: "eq",
    districtName: "",
    stateName: "",
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchDistrict, districtAutoSearchResults, isLoadingAutoSearch } = useDistrict();
  const { autoSearchState, stateAutoSearchResults } = useStateMaster();

  const [statePayload, setStatePayload] = useState(currentPayload);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateMultiValue, setStateMultiValue] = useState(false);

  const [districtPayload, setDistrictPayload] = useState(currentPayload);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districtMultiValue, setDistrictMultiValue] = useState(false);
  const [defaultDistrict, setDefaultDistrict] = useState([]);
  const [defaultState, setDefaultState] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const districtOpValue = watch("districtNameOperator");
  const stateOpValue = watch("stateNameOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.districtName != null && Object.keys(data.districtName).length != 0) {
        filters.push({
          fieldName: "districtId",
          fieldValue: convertedValue(data.districtName, "value").join(","),
          op: data.districtNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.stateName != null && Object.keys(data.stateName).length != 0) {
        filters.push({
          fieldName: "stateId",
          fieldValue: convertedValue(data.stateName, "value").join(","),
          op: data.stateNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }

      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (districtOpValue == "in" || districtOpValue == "nin") {
      setDefaultDistrict([]);
      setValue("districtName", []);
      setDistrictMultiValue(true);
    } else {
      setDefaultDistrict({});
      setValue("districtName", {});
      setDistrictMultiValue(false);
    }
  }, [districtOpValue]);
  useEffect(() => {
    if (stateOpValue == "in" || stateOpValue == "nin") {
      setDefaultState([]);
      setValue("stateName", []);
      setStateMultiValue(true);
    } else {
      setDefaultState({});
      setValue("stateName", {});
      setStateMultiValue(false);
    }
  }, [stateOpValue]);

  const fetchDistrict = async () => {
    await autoSearchDistrict(districtPayload);
  };
  useEffect(() => {
    fetchDistrict();
  }, [districtPayload]);

  const fetchState = async () => {
    await autoSearchState(statePayload);
  };
  useEffect(() => {
    fetchState();
  }, [statePayload]);

  useEffect(() => {
    if (districtAutoSearchResults.data.length !== 0) {
      setDistrictOptions([...districtOptions, ...districtAutoSearchResults.data]);
    }
  }, [districtAutoSearchResults]);

  useEffect(() => {
    if (stateAutoSearchResults.data.length !== 0) {
      setStateOptions([...stateOptions, ...stateAutoSearchResults.data]);
    }
  }, [stateAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="stateNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={stateOptions}
                label="State Name"
                payload={statePayload}
                setPayload={setStatePayload}
                loading={isLoadingAutoSearch}
                name="stateName"
                multiple={stateMultiValue}
                defaultValue={defaultState}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="districtNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={districtOptions}
                label="District Name"
                payload={districtPayload}
                setPayload={setDistrictPayload}
                loading={isLoadingAutoSearch}
                name="districtName"
                multiple={districtMultiValue}
                defaultValue={defaultDistrict}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
